import React from "react";
import { Select as ST } from "antd";
import * as styles from "./Select.module.scss";
import cn from "classnames";
import { SelectProps as AntdSelectProps } from "antd/lib/select";
import ARROW_FILLED from "@assets/icons/arrow-filled.svg";
import SVG from "react-inlinesvg";
import { Typography } from "..";
import { useInputError, ValidationProps } from "@components/Input/Input";
import { Colors } from "@helpers/Colors";

type SelectProps = AntdSelectProps<string> &
	ValidationProps & {
		label?: string;
		lightmode?: boolean;
		required?: boolean;
		isError?: boolean;
		fullWidth?: boolean;
		inputErrorColor?: Colors;
	};
function Select({
	value,
	label,
	isError,
	lightmode = false,
	fullWidth = false,

	validators,
	onValidityChange,
	forceShowError,
	required,
	emailValidator,
	inputErrorColor,
	...props
}: SelectProps) {
	const { error, onBlur } = useInputError(value || "", {
		validators,
		onValidityChange,
		forceShowError,
		required,
		emailValidator,
	});
	return (
		<div
			className={cn(styles.genemodSelectWrapper, {
				[styles.genemodSelectWrapper__lightmode]: lightmode,
			})}
		>
			<div className={styles.genemodSelectContainer}>
				<ST
					suffixIcon={
						<SVG
							src={ARROW_FILLED}
							className={styles.selectArrow}
						/>
					}
					{...props}
					style={{
						width: fullWidth ? "100%" : undefined,
						...props.style,
					}}
					value={value}
					placeholder=" "
					className={cn(styles.genemodSelect, props.className, {
						[styles.genemodSelect__lightmode]: lightmode,
						[styles.genemodSelect__error]: !!error,
					})}
					dropdownClassName={cn(
						props.dropdownClassName,
						styles.genemodSelectDropdown
					)}
					onBlur={onBlur}
				>
					{props.children}
				</ST>
				{label && (
					<div
						className={cn(styles.selectLabel, {
							[styles.selectLabel__active]: !!value,
						})}
					>
						{label}
					</div>
				)}
			</div>
			{!!error && (
				<Typography
					variant="CAPTION"
					className={styles.selectError}
					color={inputErrorColor}
				>
					{error}
				</Typography>
			)}
		</div>
	);
}
const extras = {
	Option: ST.Option,
	OptGroup: ST.OptGroup,
};
Object.assign(Select, {
	Option: ST.Option,
	OptGroup: ST.OptGroup,
});
export default Select as typeof Select & typeof extras;

const TEAM_SIZES = ["1 - 7", "8 - 15", "16 - 50", "51 - 100", "101+"];

export const TeamSizeSelect: typeof Select = (props) => {
	return (
		<Select {...props}>
			{TEAM_SIZES.map((opt) => (
				<ST.Option key={opt}>{opt}</ST.Option>
			))}
		</Select>
	);
};
