// extracted by mini-css-extract-plugin
export var body = "index-module--body--Bnp1r";
export var demoContainer = "index-module--demoContainer--unlMp";
export var demoPage = "index-module--demoPage--toJqr";
export var demoWrapper = "index-module--demoWrapper--7CQKA";
export var divider = "index-module--divider--58Jmd";
export var firstName = "index-module--firstName--D6ANC";
export var genemodLogo = "index-module--genemodLogo--O0vaC";
export var header = "index-module--header--gPagU";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--8BlTW";
export var message = "index-module--message--HbAGr";
export var names = "index-module--names--0UOM9";
export var privacyLink = "index-module--privacyLink--Huyop";
export var right = "index-module--right--OkWx8";